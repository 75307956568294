import React from 'react'
import imagenProgramacion from '../images/development.jpg'
import {AiFillLinkedin, AiOutlineInstagram, AiFillGithub} from 'react-icons/ai'
import {FaDiscord} from 'react-icons/fa'
import "../styles/Heading.css"
import { Link } from "react-router-dom";

export default function Heading() {

  return (
      <div className='row header'>
        <div className='col-sm-6' data-aos="fade-down">
            <div className='heading'>
                <h4 className='heading-hello mb-0'>Hi! Im</h4>
                <h1 className="heading-name">Joao Souza 👋</h1>
                <p className='heading-desc mb-0'>Web Developer</p>
                <div className='social mb-2'>
                    <a href='https://www.linkedin.com/in/imjoaosouza' target='_blank' rel='noreferrer' className='social-link fs-2 text-white'><AiFillLinkedin></AiFillLinkedin></a>
                    <a href='https://www.instagram.com/joaosouza7u7/' target='_blank' rel='noreferrer' className='social-link fs-2 mx-2 text-white'><AiOutlineInstagram></AiOutlineInstagram></a>
                    <a href='https://github.com/HeyArnoldo/' target='_blank' rel='noreferrer' className='social-link fs-2 mx-2 text-white'><AiFillGithub></AiFillGithub></a>
                    <a href='https://www.discord.com/users/689812474274381860' target='_blank' rel='noreferrer' className='social-link fs-2 mx-2 text-white'><FaDiscord></FaDiscord></a>
                </div>
                <Link to="/contact"><button className='btn btn-info fs-5 rounded-pill button-contact-me mt-3' data-aos="zoom-in" data-aos-duration="1200" >Contact Me!</button></Link>
            </div>
        </div>
        <div className='col-sm-6' data-aos="fade-down" data-aos-duration="1000">
            <div className='heading__img-container mt-5'>
              <img className='heading__img' alt='Programación' src={imagenProgramacion}></img>
            </div>
        </div>
      </div>)
}
