import React, { useRef } from 'react'
import swal from 'sweetalert';
import { FiSend } from "react-icons/fi";
import emailjs from '@emailjs/browser';


export default function ContactForm() {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_kmbhw1g', 'contactme-joaosouza', form.current, 'pRlsvqzMCFKmJQjwd')
            .then((result) => {
                e.target.reset();
                swal({
                    icon: "success",
                    title: "Message sent!"
                })
            }, (error) => {
                swal({
                    icon: "error",
                    title: "Oops! Something went wrong."
                })
            });        
    };
  return (
    <form ref={form} onSubmit={sendEmail} className='contact-container p-4' data-aos="zoom-in-down">
                  <label htmlFor="name" className="form-label">NAME</label>
                  <input type="text" className="form-control mb-4" id="name" placeholder="Your name" name="name"required/>
                  <label htmlFor="email" className="form-label">EMAIL</label>
                  <input type="email" className="form-control mb-4" id="email" placeholder="name@example.com" name="email"required/>
                  <label htmlFor="message" className="form-label">MESSAGE</label>
                  <textarea className="form-control mb-4" id="message" rows="6" placeholder='Hi Joao, whats up? 😉' name="message" required/>
              <div className='d-flex justify-content-end'>
                <button type="submit" className="btn btn-info  mb-3 px-5"><span className='mx-2'>Send</span> <FiSend /></button>
              </div>
    </form>
    )
}
