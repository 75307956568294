import React from 'react'
import { uploadFile } from '../services/files/uploadFile'
import { BsFillImageFill, BsDownload } from 'react-icons/bs'
import { AiFillFile, AiOutlineLink } from 'react-icons/ai'

import "../styles/DragAndDrop.css"
export default function DragAndDrop() {
    let [file, setFile] = React.useState(null)
    const [fileInfo, setFileInfo] = React.useState()
    const [chunks, setChunks] = React.useState([]);
    const [numberOfChunks, setNumberOfChunks] = React.useState(0)
    const [whatChunk, setWhatChunk] = React.useState(0)

    let inputRef = React.useRef()

    const handleDragOver = (e) => {
        e.preventDefault()
    }
    const handleDrop = (e) => {
        e.preventDefault()
        const file = e.target.files[0]
        const chunkSize = 1024 * 1024 * 95; // Tamaño del fragmento en bytes
        const fileSize = file.size;
        const numChunks = Math.ceil(fileSize / chunkSize);
        setNumberOfChunks(numChunks)
        for (let i = 0; i < numChunks; i++) {
            const start = i * chunkSize;
            const end = Math.min(fileSize, start + chunkSize);
            const chunk = file.slice(start, end);
            const newChunk = new Blob([chunk], { type: chunk.type });
            newChunk.index = i;
            newChunk.totalChunks = numChunks;
            newChunk.name = file.name;
            setChunks((prevChunks) => [...prevChunks, newChunk]);
        }
        setFile(e.target.files[0])
    }
    const handleUpload = async () => {
        let totalChunks = chunks.length;
        let name = file.name;

        for (let index = 0; index < totalChunks; index++) {
            let chunk = chunks[index];
            await uploadFile(file, totalChunks, name, index, chunk).then(res => {
                console.log(res)
                setWhatChunk(index + 1)
                if (index === totalChunks - 1) {
                    setFileInfo(res.data)
                }
            })
        }
    }
    if (file) return (
        <div className='container fs-5'>
            <div className='row'>
                <div className='col-sm-2 d-flex justify-content-center'>
                    <span className='fs-1'>
                        {file.type.includes("image") || file.type.includes("video") ? <BsFillImageFill /> : <AiFillFile />}
                    </span>
                </div>
                <div className='col-sm-10'>
                    <div className='row g-3'>
                        <div className='col-12 text-center'>
                            <span className='text-white fw-bold'>{file.name}</span>
                        </div>
                        <div className='col-sm-6 text-center'>
                            <p className='text-white fw-bold'>{Math.round(file.size / 1024 / 1024)} mb</p>
                        </div>
                        <div className='col-sm-6 text-center'>
                            <p className='text-white fw-bold'>Chunks: {whatChunk}/{numberOfChunks}</p>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            {
                fileInfo ?
                    <div className='row mt-3 g-3'>
                            <div className='col-sm-6 col-12 text-center'>
                                <button className='btn btn-light h-100 w-100' onClick={() => navigator.clipboard.writeText("https://joaosouza.me" + fileInfo.path)}>Link <AiOutlineLink /></button>
                            </div>
                            <div className='col-xxl-6 col-lg-6 col-sm-6 col-12 text-center'>
                                <a href={fileInfo.path}>
                                    <button className='btn btn-info h-100 w-100 px-0'>Download <BsDownload /></button>
                                </a>
                            </div>
                    </div> 
                :
                <div className='text-center'>
                    <button onClick={handleUpload} className="btn btn-info fs-4">Upload</button>
                </div>
            }
        </div>
    )

    return (
        <div className='container'>
            {
                !file &&
                <div
                    className='dropzone'
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                >
                    <input
                        type='file'
                        onChange={handleDrop}
                        hidden
                        ref={inputRef}
                    />
                    <button onClick={() => inputRef.current.click()} className='btn btn-info fs-5 mt-5'>Select File</button>
                </div>
            }
        </div>
    )
}
