import React from 'react'
import { getImage } from "../services/openai/getImage"
import { AiFillHome } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import ArtificialIntelligentOutputImages from './ArtificialIntelligentOutputImages'

export default function ArtificialIntelligentFormImages() {
    const [ prompt , setPrompt ] = React.useState('')
    const [ ammount , setAmmount ] = React.useState(1)
    const [ output , setOutput ] = React.useState('')
    const [ loading , setLoading ] = React.useState(false)
    const handleOnSubmit = async (e) => {
        e.preventDefault()
        setPrompt('')
        setLoading(true)
        await getImage(prompt, ammount, e.target.radio.value).then(res => {
            setOutput(res)
            setLoading(false)
        });
    }
  return (
    <div className='d-flex flex-column align-items-center w-100 my-5'>
    <h3 className='mb-3 fw-bold text-center'>Image 📷</h3>
      <div className='container glass p-3 mb-3'>
        {loading ? <div className='d-flex justify-content-center mb-3'><div className="spinner-border text-info" role="status"></div></div>:null}
        <form onSubmit={handleOnSubmit} className='form-control-dark'>
          <div className='container row mb-3 mx-3'>
                      <div className="form-check col-sm-4 d-flex justify-content-center">
                          <input className="form-check-input" type="radio" name="radio" id="radio-256" value="256x256" />
                          <label className="form-check-label mx-1">
                            256x256
                          </label>
                      </div>
                      <div className="form-check col-sm-4 d-flex justify-content-center">
                          <input className="form-check-input" type="radio" name="radio" id="radio-512" value="512x512"/>
                          <label className="form-check-label mx-1">
                            512x512
                          </label>
                      </div>
                      <div className="form-check col-sm-4 d-flex justify-content-center">
                          <input className="form-check-input" type="radio" name="radio" id="radio-1024" value="1024x1024" />
                          <label className="form-check-label mx-1">
                            1024x1024
                          </label>
                      </div>
          </div>
            <div className='d-flex align-items-center mb-3'>
              <select className="form-select model-select" id="model" onChange={e => setAmmount(Number(e.target.value))}>
                <option value="1">1 Image</option>
                <option value="2">2 Images</option>
                <option value="3">3 Images</option>
                <option value="4">4 Images</option>
              </select>
            </div>
          <div className='input-group'>
            <input type='text' className='form-control' id='input-prompt' aria-describedby='emailHelp' placeholder='Enter your prompt here' value={prompt} onChange={e => setPrompt(e.target.value)} required />
            <button type='submit' className='btn btn-info'>Send</button>
          </div>
        </form>
      </div>
        <div className='container glass p-3 mb-3'>
        <ArtificialIntelligentOutputImages output={output}/>
      </div>
      <Link to="/services"><button className='btn btn-info glass px-5 py-2 mt-5 rounded-pill'><span className='mx-2'>Back Services</span> <AiFillHome /></button></Link>
    </div>
  )
}
