import React from 'react'
import {FiExternalLink} from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { AiFillHome } from 'react-icons/ai'

export default function Services() {
  return (
    <div className='mt-5 d-flex flex-column align-items-center'>
        <div className='mb-3' data-aos="fade-down">
            <h3 className='mb-3 fw-bold text-center'>Services</h3>
            <p className='text-white text-center mb-4'>Some useful services tools be a good developer 😉</p>
        </div>
        <div className='row w-100'>
            <div className='col-12 col-sm-6 col-md-4 text-center p-2' data-aos="fade-down" data-aos-duration="1200">
              <div className='glass p-3'>
              <h4 className=''>Artifical Intelligence</h4>
              <p className='text-white text-center mb-4'>
              Chat with AI and get answers to your questions
              </p>
              <Link to="/ai"><button className='btn btn-info'>More Info <FiExternalLink /></button></Link>
              </div>
            </div>
            <div className='col-12 col-sm-6 col-md-4 text-center p-2' data-aos="fade-down" data-aos-duration="1500">
              <div className='glass p-3'>
              <h4 className=''>File Upload</h4>
              <p className='text-white text-center mb-4'>
              Upload temporary files to share with others
              </p>
              <Link to="/file-upload"><button className='btn btn-info'>More Info <FiExternalLink /></button></Link>
              </div>
            </div>
            <div className='col-12 col-sm-6 col-md-4 text-center p-2' data-aos="fade-down" data-aos-duration="2000">
              <div className='glass p-3'>
                <h4 className=''>ToDo</h4>
                <p className='text-white text-center mb-4'>
                  Simple ToDo app to manage your tasks
                </p>
                <Link to="/todo-app"><button className='btn btn-info'>More Info <FiExternalLink /></button></Link>
              </div>
            </div>
            {/* <div className='col-12 col-sm-6 col-md-4 text-center p-2'>
              <div className='glass p-3'>
              <h4 className=''>MC Builds</h4>
              <p className='text-white text-center mb-4'>
              User interface to view MC Builds
              </p>
              <Link to="/builds"><button className='btn btn-info'>More Info <FiExternalLink /></button></Link>
              </div>
            </div> */}
        </div>
        <Link to="/home"><button className='btn btn-info glass px-5 py-2 mt-5 rounded-pill'><span className='mx-2'>Back Home</span> <AiFillHome /></button>
        </Link>
    </div>
    )
}
