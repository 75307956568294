import React from 'react'
import {AiFillLinkedin, AiOutlineInstagram, AiFillGithub} from 'react-icons/ai'
import {FaDiscord} from 'react-icons/fa'

import "../styles/Footer.css"
export default function Footer() {
  return (
      <footer className=''>
          <svg  className="footersvg"viewBox="0 0 120 18">
              <defs>
                  <filter id="goo">
                      <feGaussianBlur in="SourceGraphic" stdDeviation="2" result="blur" />
                      <feColorMatrix in="blur" mode="matrix" values="
               1 0 0 0 0  
               0 1 0 0 0  
               0 0 1 0 0  
               0 0 0 13 -9" result="goo" />
                      <feBlend in="SourceGraphic" in2="goo" />
                  </filter>
                  <path id="wave"
                      d="M 0,10 C 30,10 30,15 60,15 90,15 90,10 120,10 150,10 150,15 180,15 210,15 210,10 240,10 v 28 h -240 z" />
              </defs>

              <use id="wave3" className="wave" xlinkHref="#wave" x="0" y="-2"></use>
              <use id="wave2" className="wave" xlinkHref="#wave" x="0" y="0"></use>
              <use id="wave1" className="wave" xlinkHref="#wave" x="0" y="1" />
          </svg>

          <div className='py-3 footer-background'>
            <div className='mb-2 text-center'>
                    <a href='https://www.linkedin.com/in/joao-souza-937037243/' target='_blank' rel='noreferrer' className='fs-2 mx-2 text-white'><AiFillLinkedin></AiFillLinkedin></a>
                    <a href='https://www.instagram.com/joaosouza7u7/' target='_blank' rel='noreferrer' className='fs-2 mx-2 text-white'><AiOutlineInstagram></AiOutlineInstagram></a>
                    <a href='https://github.com/HeyArnoldo/' target='_blank' rel='noreferrer' className='fs-2 mx-2 text-white'><AiFillGithub></AiFillGithub></a>
                    <a href='https://www.discord.com/users/689812474274381860' target='_blank' rel='noreferrer' className='fs-2 mx-2 text-white'><FaDiscord></FaDiscord></a>
            </div>
            <div className='m-3 text-center fs-5'>
                <span className='text-white text-uppercase fw-bold'>Joao Souza</span>
            </div>
            <div>
                <p className='text-center text-white'>© 2023 Joao Souza. All rights reserved.</p>
            </div>
          </div>
      </footer>
  )
}
