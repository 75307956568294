import axios from "axios"

export const uploadFile = async (file, totalChunks, name, index , chunk) => {
    const dataToUpload = new FormData()
    dataToUpload.append("totalChunks", totalChunks)
    dataToUpload.append("name", name)
    dataToUpload.append("index", index)
    dataToUpload.append("chunk", chunk)
    dataToUpload.append("file_size", file.size)
    dataToUpload.append("file_type", file.type)

    const response = await axios.post("/api/files", dataToUpload, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
    let { data } = response
    return data
}