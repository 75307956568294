import React from 'react'
import bcrecords from '../images/bcrecords.png'
import cucuentame from '../images/cucuentame.png'
import whatsappbot from '../images/whatsappbot.png'
import movieapp from '../images/movieapp.png'
import {FiExternalLink} from 'react-icons/fi'

export default function Projects() {
  return (
    <div className='d-flex align-items-center justify-content-center my-5 flex-column'>
      <h3 className='aboutme__title my-5' data-aos="zoom-in-down">Projects</h3>
      <div className='row g-4'>
        <div className='col-lg-4 col-sm-6 d-flex justify-content-center text-center' data-aos="fade-down" data-aos-duration="1000">
          <div className="card card-projects">
            <img className="card-img-top" src={bcrecords} alt="BCRecords" />
            <div className="card-body">
            <h5 className="card-title">BCRecords</h5>
            <p className="card-text">Portfolio website using react and apache</p>
            <a href="https://bcrecords.tk/" target="_blank"  rel="noreferrer" className="btn btn-info"><FiExternalLink /></a>
          </div>
          </div>
        </div>
        <div className='col-lg-4 col-sm-6 d-flex justify-content-center text-center' data-aos="fade-down" data-aos-duration="1500">
          <div className="card card-projects">
            <img className="card-img-top" src={movieapp} alt="Whatsapp Bot" />
            <div className="card-body">
            <h5 className="card-title">Movie App</h5>
            <p className="card-text">Simple Movie App using Angular and AWS Services</p>
            <a href="https://heldencraft.tk" target="_blank"  rel="noreferrer" className="btn btn-info"><FiExternalLink /></a>
            </div>
          </div>
        </div>
        {/* <div className='col-lg-4 col-sm-6 d-flex justify-content-center text-center'>
          <div class="card card-projects">
            <img class="card-img-top" src={mcwebpage} alt="MCWeb" />
            <div class="card-body">
            <h5 class="card-title">MC Web Page</h5>
            <p class="card-text">MC Web Page using HTML & CSS Native</p>
            <a href="/mc-web" target="_blank"  rel="noreferrer" class="btn btn-info"><FiExternalLink /></a>
            </div>
          </div>
        </div> */}
        {/* <div className='col-lg-4 col-sm-6 d-flex justify-content-center text-center'>
          <div class="card card-projects">
            <img class="card-img-top" src={joaosouza} alt="JoaoSouza" />
            <div class="card-body">
            <h5 class="card-title">Joao Souza Portfolio</h5>
            <p class="card-text">Developer Portfolio using react</p>
            <a href="/" target="_blank"  rel="noreferrer" class="btn btn-info"><FiExternalLink /></a>
            </div>
          </div>
        </div> */}
        <div className='col-lg-4 col-sm-6 d-flex justify-content-center text-center' data-aos="fade-down" data-aos-duration="2000">
          <div className="card card-projects">
            <img className="card-img-top" src={cucuentame} alt="CUCuentame" />
            <div className="card-body">
            <h5 className="card-title">CuCuentame</h5>
            <p className="card-text">Posting App using React, ExpressJS and Bootstrap</p>
            <a href="https://cucuentame.tk/" target="_blank"  rel="noreferrer" className="btn btn-info"><FiExternalLink /></a>
            </div>
          </div>
        </div>
        {/* <div className='col-lg-4 col-sm-6 d-flex justify-content-center text-center'>
          <div class="card card-projects">
            <img class="card-img-top" src={discordbot} alt="Discord Bot" />
            <div class="card-body">
            <h5 class="card-title">Discord Bot</h5>
            <p class="card-text">Multifunctional Bot using NodeJS and Discord API</p>
            <a href="/"  target="_blank"  rel="noreferrer" class="btn btn-info"><FiExternalLink /></a>
            </div>
          </div>
        </div> */}
        <div className='col-lg-12 col-sm-6 d-flex justify-content-center text-center' data-aos="fade-up" data-aos-duration="1000">
          <div className="card card-projects glass">
            <img className="card-img-top" src={whatsappbot} alt="Whatsapp Bot" />
            <div className="card-body">
            <h5 className="card-title">Whatsapp Bot</h5>
            <p className="card-text">Whatsapp Sticker Bot using NodeJS</p>
            <a href="https://wa.me/51960941625" target="_blank"  rel="noreferrer" className="btn btn-info"><FiExternalLink /></a>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  )
}
