import React from 'react'
import ArtificialIntelligentForm from '../components/ArtificialIntelligentForm'
import ArtificialIntelligentFormImages from '../components/ArtificialIntelligentFormImages'

export default function Ia() {
  return (
    <div className='mt-5 d-flex flex-column align-items-center'>
        <div className='mb-3'>
            <h3 className='mb-3 fw-bold text-center'>Chat with AI 🤖</h3>
            <p className='text-white text-center mb-4'>One Simple Dashboard to use AI</p>
        </div>
        <ArtificialIntelligentForm />
        <ArtificialIntelligentFormImages />
    </div>
  )
}
