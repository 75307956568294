import React, {useState, useEffect} from 'react'
import { getAllBuilds } from '../services/build/getAllBuilds'
import NoImage from '../images/no-image.png'
import {AiFillFileZip, AiFillFile} from 'react-icons/ai'
import {FiExternalLink} from 'react-icons/fi'
import {Link} from 'react-router-dom'

export default function Builds() {
    let [builds, setBuilds] = useState([])
    let [loading, setLoading] = useState(false)

    useEffect( () => {
        setLoading(true)
        getAllBuilds().then(data => {
            setBuilds(data);
            setLoading(false);
        })
    }, [])
  return (
    <div className='mt-5 d-flex flex-column align-items-center'>
        <div className='mb-3'>
            <h3 className='mb-3 fw-bold text-center'>MC Builds 😉</h3>
            <p className='text-white text-center mb-4'>User interface to view MC Builds</p>
        </div>
        <div className='row g-5'>
            {
                loading ? <div className='spinner-border text-info'></div> : null
            }
            {
                builds.map((build, index) => {
                    return (
                        <div className='col-12 col-md-6 col-lg-4 glass p-4' key={index}>
                            <Link to={`/builds/${build.name}`} className='text-decoration-none text-white'><h4 className='text-center'>{build.name}</h4></Link>                            {
                                build.files.map(file => {
                                    if (file.endsWith(".schematic") || file.endsWith(".schem"))return(<p><a className='text-decoration-none fs-5' href={""+file}><AiFillFile className='fs-3'/> Schematic File</a></p>)
                                    return(<p><a className='text-decoration-none fs-5 text-warning' href={""+file}><AiFillFileZip className='fs-3'/> Zipped File</a></p>)
                                })
                            }
                            {   
                                build.images.length === 0 ? <Link to={`/builds/${build.name}`}><div className='text-center'><img className='img-fluid mb-3' src={NoImage} alt='NoImage' /></div></Link> :
                                build.images.map((image, index) => {
                                    if (index > 0 )return null
                                    return(
                                    <Link to={`/builds/${build.name}`}><img className='img-fluid mb-3 rounded-5' src={"" + image} alt="Builds" key={image} /></Link>)
                                })
                            }
                            {
                                <div className='text-center'>
                                    <Link to={`/builds/${build.name}`}><button className='btn btn-info my-4'>More Info <FiExternalLink /></button></Link>
                                </div>
                            }
                        </div>
                    )
                })
            }
        </div>
    </div>
  )
}
