import React from 'react'
import {TodoList} from '../components/TodoList'
import { AiFillHome } from 'react-icons/ai'
import { Link } from 'react-router-dom'

export default function TodoApp() {
  return (
    <div className='container-sm p-4 bg-dark text-center'>
    <h1 className='display-4 text-black'>Tareas de Joao</h1>
        <TodoList/>
        <Link to="/services"><button className='btn btn-info glass px-5 py-2 mt-5 rounded-pill'><span className='mx-2'>Back Services</span> <AiFillHome /></button>
        </Link>
    </div>
    )
}
