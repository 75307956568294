import React from 'react'
import "../styles/Todo.css"
import { AiFillDelete } from "react-icons/ai"
import { FaCopy } from "react-icons/fa";

export function Todo({ text, completed, id, completeTodo, removeTodo, copyTextTodo}) {
  return (
    <div className={completed ? "todo completed text-white" : "todo text-white"}>
        <div className='todo-text' onClick={() => completeTodo(id)}>
            {text}
        </div>
        <div className='todo-icon-container'>
            <FaCopy className='todo-icon' onClick={() => copyTextTodo(id)}/>
        </div>
        <div className='todo-icon-container'>
            <AiFillDelete className='todo-icon' onClick={() => removeTodo(id)}/>
        </div>
    </div>
  )
}
