import React from 'react'
import "../styles/Technologies.css"
import wink from '../images/wink.png'
export default function Technologies() {
  return (
    <div className='container row mx-0 g-4'>
        <div className='col-md-8 tech__container rounded-5 p-5 pt-3' data-aos="fade-down">
            <span className='tech__title text-white'>Technologies 💻</span>
            <div className='text-white text-center'>
            As a web developer, I make use of a variety of technologies to create user-friendly and comprehensive web applications. One of my favorite technology stacks includes React, Angular, Node.js, and AWS.
            </div>
        </div>
        <div className='col-md-4 d-flex align-items-center justify-content-center' data-aos="zoom-in-down">
            <img src={wink} alt="" className='wink__img'/>
        </div>
    </div>
  )
}
