import React from 'react'
import { getCompletion } from "../services/openai/getCompletion"
import ArtificialIntelligentOutput from './ArtificialIntelligentOutput'


export default function ArtificialIntelligentForm() {
  const [tokens, setTokens] = React.useState(50)
  const [model, setModel] = React.useState('text-davinci-003')
  const [prompt, setPrompt] = React.useState('')
  const [output, setOutput] = React.useState('')
  const [loading, setLoading] = React.useState(false);

  const handleSubmitText = async (e) => {
    e.preventDefault()
    setLoading(true)
    setPrompt('')
    try {
      await getCompletion(model, prompt, tokens).then(res => {
        setOutput(res[0].text)
        setLoading(false)
      });
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <>
    <div className='d-flex flex-column align-items-center w-100 mb-5'>
    <h3 className='mb-3 fw-bold text-center'>Text 🧾</h3>
      <ArtificialIntelligentOutput output={output}/>
      <div className='container glass p-3'>
        {loading ? <div className='d-flex justify-content-center mb-3'><div class="spinner-border text-info" role="status"></div></div>:null}
        <form onSubmit={handleSubmitText} className='form-control-dark'>
          <div className='form-group row mb-3'>
            <div className='col-sm-6 d-flex align-items-center flex-column mb-2'>
              <label htmlFor='tokens' className='form-label mb-0 text-white'>Tokens: {tokens}</label>
              <input type="range" className="form-range" min="50" max="1000" step="50" id="tokens" onChange={e => setTokens(e.target.value)} />
            </div>
            <div className='col-sm-6 d-flex align-items-center'>
              <select className="form-select model-select" id="model" onChange={e => setModel(e.target.value)}>
                <option value="text-davinci-003">🔝 text-davinci-003</option>
                <option value="text-curie-001">💤 text-curie-001</option>
                <option value="text-ada-001">💤 text-ada-001</option>
                <option value="text-babbage-001">💤 text-babbage-001</option>
              </select>
            </div>
          </div>
          <div className='input-group'>
            <input type='text' className='form-control' id='input-prompt' aria-describedby='emailHelp' placeholder='Enter your prompt here' value={prompt} onChange={e => setPrompt(e.target.value)} required />
            <button type='submit' className='btn btn-info'>Send</button>
          </div>
        </form>
      </div>
    </div>
    </>
  )
}
