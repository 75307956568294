import React from 'react'
import DragAndDrop from '../components/DragAndDrop'
import FileList from '../components/FileList'
import { AiFillHome } from 'react-icons/ai'
import { Link } from 'react-router-dom'

export default function FileUpload() {
  return (
    <div className='mt-5 d-flex flex-column align-items-center'>
        <div className='mb-3'>
            <h3 className='mb-3 fw-bold text-center'>Fast File Upload</h3>
            <p className='text-white text-center mb-4'>Simple File Upload to share files with others persons😉</p>
        </div>
        <div className='container row'>
            <div className='mt-3 mb-5 col-lg-8'>
              <div className='glass p-4'>
                <DragAndDrop />
              </div>
            </div>
            <div className='mt-3 col-lg-4'>
                <FileList />
            </div>
        </div>
        <Link to="/services"><button className='btn btn-info glass px-5 py-2 mt-5 rounded-pill'><span className='mx-2'>Back Services</span> <AiFillHome /></button>
        </Link>
    </div>
  )
}
