import React from 'react'
import "../styles/AboutMe.css"
import blob1 from '../images/blob1.svg'
import blob2 from '../images/blob2.svg'
import blob3 from '../images/blob3.svg'
export default function AboutMe() {
  return (
    <div className='d-flex align-items-center justify-content-center my-5 flex-column'>
      <div className='d-flex align-items-center justify-content-center blob__container'>
        <img src={blob1} alt="" className="aboutme__blob1" data-aos="fade-left" data-aos-duration="1000"/>
        <img src={blob2} alt="" className="aboutme__blob2" data-aos="fade-up" data-aos-duration="1500"/>
        <img src={blob3} alt="" className="aboutme__blob3" data-aos="fade-right" data-aos-duration="1000"/>
      </div>
      <div className='aboutme__container rounded-5 p-5 pt-3' data-aos="zoom-in" data-aos-duration="500">
        <div className='aboutme__title mb-3'>
          About Me ✌
        </div>
        <div className='text-white text-center'>
              My name is <strong className='font-weight-bold'>Joao Souza</strong>, and I am a web developer specializing in creating eye-catching, custom, and user-friendly websites. With over 2 years of experience in the web development industry, I have the technical know-how to create the perfect website for your business.
        </div>
      </div>
      {/* <div className='row mt-5 w-75'>
        <div className='container-6 text-center'>
          <h3 className='aboutme__title'>About Me.</h3>
            <p className='aboutme__desc'>
              My name is Joao Souza, and I am a web developer specializing in creating eye-catching, custom, and user-friendly websites. With over 2 years of experience in the web development industry, I have the technical know-how to create the perfect website for your business. <br />
              I specialize in creating websites from scratch. All of my websites are designed with the user experience in mind, ensuring that your site is intuitive and easy to navigate for viewers. Additionally, I also offer website update and maintenance services to keep your website up to date and compliant with industry standards.<br />
              If you’re in search of a reliable web developer with years of experience, look no further - I’m here to help you create the perfect website for your business. <br />
              Don’t hesitate to get in touch to discuss your project - I look forward to hearing from you!
            </p>
        </div>
      </div> */}
    </div>
  )
}
