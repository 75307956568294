import React from 'react'
import iaimg from "../images/ia.jpg"
export default function ArtificialIntelligentOutputImages({output}) {
    let imgs = [
        iaimg,
        iaimg,
        iaimg,
        iaimg
    ]
    if (output) {
        output.data.map((img, index) => {
            return imgs[index] = output.data[index].url
        })
    }
  return (
    <div className='d-flex justify-content-center align-items-center row g-3'>
        {
            imgs.map((img, index) => {
                return <img src={img} alt='output' className='img-fluid col-sm-6 col-md-3' key={index}/>
            })
        }
    </div>
  )
}
