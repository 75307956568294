import './App.css';
import './styles/bootstrap.min.css';
import { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import Main from './views/Main';
import TodoApp from './views/TodoApp';
import Nav from './components/Nav';
import Footer from './components/Footer';
import Contact from './views/Contact';
import Services from './views/Services';
import Ia from './views/Ia';
import Builds from './views/Builds';
import BuildsID from './views/BuildsID';
import FileUpload from './views/FileUpload';
import AOS from 'aos';
import 'aos/dist/aos.css';
import NotFound from './views/NotFound';
import Stream from './views/Stream';
import Login from './views/Login';
import Register from './views/Register';
import Dashboard from './views/Dashboard';

function App() {
  useEffect(() => {
    AOS.init({duration: 1000});
  }, []);
  return (
    <div className="App">
      <Nav />
      <div className='container'>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/home" element={<Main />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/todo-app" element={<TodoApp />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/ai" element={<Ia />} />
          <Route path="/ia" element={<Ia />} />
          <Route path="/builds" element={<Builds />} />
          <Route path="/builds/:id" element={<BuildsID />} />
          <Route path="/file-upload" element={<FileUpload />} />
          <Route path="/streaming/:file" element={<Stream />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
