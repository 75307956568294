const { Configuration, OpenAIApi } = require("openai");

const configuration = new Configuration({
  apiKey: "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",
});
const openai = new OpenAIApi(configuration);

export const getImage = async (prompt, n = 1, size) => {
    size = size || "256x256";
    const response = await openai.createImage({
        prompt: prompt,
        n: n,
        size: size,
    })
    return response.data;
}
