import React, { useEffect} from 'react'
import {useParams } from 'react-router-dom'
import VideoJS from '../components/VideoJS'

export default function Stream() {
  const { file } = useParams()
  
  useEffect(() => {
  }, [file])
  return (
    <div className='my-5 container d-flex justify-content-center align-items-center flex-column'>
      <h3 className='mb-3 fw-bold text-center fs-5' data-aos="fade-down" >Ver {file}</h3>

      {/* <VideoJS file={"https://joaosouza.me/api/files/stream/" + file} /> */}
      {
        <iframe title="StreamingVideo" src={"https://joaosouza.me/api/files/stream/" + file} frameborder="0" allowFullScreen></iframe>
      }
    </div>
  )
}
