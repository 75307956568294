const { Configuration, OpenAIApi } = require("openai");

const configuration = new Configuration({
  apiKey: "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",
});
const openai = new OpenAIApi(configuration);

export const getCompletion = async (model, prompt, tokens, temperature) => {
    tokens = Number(tokens) || 100;
    temperature = temperature || 0.9;
    
    const completion = await openai.createCompletion({
        model: model,
        prompt: prompt,
        temperature: temperature,
        max_tokens: tokens,
    });
    return completion.data.choices;
}