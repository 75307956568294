import React from 'react'
import { useCookies } from 'react-cookie';


export default function Dashboard() {
    let [cookies] = useCookies(['token']);
    if (!cookies.token) {
        return window.location.href = '/login'
    }
  return (
    <div className='container justify-content-center align-items-center mt-5'>
        <h3 className='text-center mb-3'>Dashboard</h3>
        <div className='text-center mt-3'>
            Hola! {cookies.username} ({cookies.id})
        </div>
    </div>
  )
}
