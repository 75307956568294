import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logoBlanco from '../images/logo-blanco.png'
import { Link } from "react-router-dom";
import '../styles/NavBar.css'
import { useCookies } from 'react-cookie';


function BasicExample() {
  const [cookies, setCookie] = useCookies(['token']);

  return (
    <Navbar expand="md" variant="dark" className='navbar sticky-top'>
      <Container>
        <Navbar.Brand>
            <Link to="/home">
              <h3 className='m-0 fs-5'><img src={logoBlanco} alt='logo' className='logo' /> Joao</h3>
            </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className='me-auto'/>
          <Nav>
            <Nav.Link className='my-2'>
                {
                  cookies.token ? <span className='mx-2 nav_link rounded-pill p-2'><button className='btn btn-danger' onClick={() => setCookie('token', '', { path: '/' })}>Logout</button></span> :
                  <Link to="/login"><span className='mx-2 nav_link rounded-pill p-2'><button className='btn btn-info'>Login</button></span></Link>
                }
            </Nav.Link>
            <Nav.Link className='my-3'>
                <Link to="/home"><span className='mx-3 nav_link rounded-pill p-2 my-3'>Home</span></Link>
            </Nav.Link>
            <Nav.Link className='my-3'>
                <Link to="/services"><span className='mx-3 nav_link rounded-pill p-2'>Services</span></Link>
            </Nav.Link>
            <Nav.Link className='my-3'>
                <Link to="/contact"><span className='mx-3 nav_link-contact rounded-pill p-3'>Contact</span></Link>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default BasicExample;