import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { register } from '../services/auth/register'
import { useCookies } from 'react-cookie';
import swal from 'sweetalert';

export default function Login() {
    let [username, setUsername] = useState('')
    let [password, setPassword] = useState('')
    let [confirmPassword, setConfirmPassword] = useState('')
    let [success, setSuccess] = useState(false)
    let [loading, setLoading] = useState(false)
    const [cookies] = useCookies(['token']);

    if (cookies.token) {
        window.location.href = '/dashboard'
    }
    
    if (success) {
        return (
            <div className='container justify-content-center align-items-center'>
                <div className='row justify-content-center align-items-center'>
                    <div className='col-12 col-md-6 col-lg-4 glass p-4 mt-5'>
                        <h3 className='text-center mb-3'>Register</h3>
                        <div className='text-center mt-3'>
                            <Link to='/login'>You are registered! Login</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    function handleSubmit(e) {
        e.preventDefault()
        if (password !== confirmPassword) {
            swal("Error!", "Passwords don't match!", "error")
            return;
        }
        setLoading(true)
        let data = register(username, password)
        data.then(res => {
            if (res.error) {
                swal("Error!", res.error, "error")
                setLoading(false)
                return;
            }
            swal("Success!", `Your account has been registered!`, "success")
            setLoading(false)
            setSuccess(true)
        }) 
    }

  return (
    <div className='container justify-content-center align-items-center'>
        <div className='row justify-content-center align-items-center'>
            <div className='col-12 col-md-6 col-lg-4 glass p-4 mt-5'>
                <h3 className='text-center mb-3'>Register</h3>
                <form onSubmit={handleSubmit}>
                    <div className='mb-3'>
                        <label htmlFor='username' className='form-label'>Username</label>
                        <input value={username} type='text' className='form-control' id='username' onChange={e => setUsername(e.target.value)}/>
                    </div>
                    <div className='mb-3'>
                        <label htmlFor='password' className='form-label'>Password</label>
                        <input value={password} type='password' className='form-control' id='password' onChange={e => setPassword(e.target.value)} />
                    </div>
                    <div className='mb-3'>
                        <label htmlFor='password' className='form-label'>Confirm Password</label>
                        <input value={confirmPassword} type='password' className='form-control' id='password' onChange={e => setConfirmPassword(e.target.value)} />
                    </div>
                    <div className='text-center'>
                        <button className='btn btn-info'>Register</button>
                    </div>
                </form>
                <div className='text-center mt-3'>
                    {loading ?? <div className="spinner-border text-info" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>}
                </div>
                <div className='text-center mt-3'>
                    <Link to='/register'>Have an account? Login</Link>
                </div>
            </div>
        </div>
    </div>
  )
}
