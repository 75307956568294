import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { getBuild } from '../services/build/getBuild'
import NoImage from '../images/no-image.png'
import { AiFillFileZip, AiFillFile } from 'react-icons/ai'

export default function BuildsID() {
    const { id } = useParams()
    let [build, setBuild] = useState({})
    let [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        getBuild(id).then(data => { setBuild(data); setLoading(false) })
    }, [id])
    
    return (
        <div className='mt-5 d-flex flex-column align-items-center'>
            <div className='mb-3'>
                <h3 className='mb-3 fw-bold text-center'>{id}</h3>
            </div>
            <div className='row g-3'>
                {
                    loading ? null : 
                    build.files.map((file,index) => {
                        if (file.endsWith(".schematic") || file.endsWith(".schem")) return (<div className='text-center col-md-12'><a className='text-decoration-none fs-5' href={"" + file} key={index}><AiFillFile className='fs-3' /> Schematic File</a></div>)
                        return (<div className='text-center col-md-12'><a className='text-decoration-none fs-5 text-warning' href={"" + file}><AiFillFileZip className='fs-3' /> Zipped File</a></div>)
                    })
                }
                {
                    loading ? <div className='spinner-border text-info'></div> : build.images.length === 0 ? <div className='text-center'><img className='img-fluid mb-3' src={NoImage} alt='NoImage' /></div>:
                    build.images.map((image, index) => {
                        return (
                            <div className='col-12 col-md-6 p-4'><img className='img-fluid mb-3 rounded-5' src={"" + image} alt="Builds" /></div>)
                    })
                }
            </div>
            <Link to="/builds"><button className='btn btn-info my-5 fs-4'>Back to Builds</button></Link>
        </div>
    )
}
