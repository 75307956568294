import React from 'react'
import "./styles/contact.css"
import {AiOutlineInstagram, AiOutlineMail, AiFillHome} from 'react-icons/ai'
import {FaDiscord} from 'react-icons/fa'
import { Link } from 'react-router-dom';
import ContactForm from '../components/ContactForm';


export default function Contact() {
  return (
    <div className='my-5 container d-flex justify-content-center align-items-center flex-column'>
        <h3 className='mb-3 fw-bold text-center' data-aos="fade-down" >Let's chat 💬</h3>
        <p className='text-white text-center contact-tip mb-4' data-aos="fade-down" >Have an inquiry, or want to connect? Feel free to leave a message below, or get in touch via Discord, Instagram, or email.</p>
        <div className='contact-links row mb-4 g-3 text-center d-flex justify-content-center' data-aos="zoom-in-down" >
            <a href='https://www.discord.com/users/689812474274381860' target='_blank' rel='noreferrer'  className='contact-link col-md-3 glass p-2 mx-3 text-white'>
                Discord <FaDiscord />
            </a>
            <a href='https://www.instagram.com/joaosouza7u7/' target='_blank' rel='noreferrer'  className='contact-link col-md-3 glass p-2 mx-3 text-white'>
                Instagram <AiOutlineInstagram />
            </a>
            <a href='mailto: joaosouzareyna@outlook.com' target='_blank' rel='noreferrer'  className='contact-link col-md-3 glass p-2 mx-3 text-white'>
                Email <AiOutlineMail />
            </a>
        </div>
        <ContactForm />
        <Link to="/home"><button className='btn btn-info glass px-5 py-2 mt-5 rounded-pill'><span className='mx-2'>Back Home</span> <AiFillHome /></button>
        </Link>
    </div>
  )
}
