import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { login } from '../services/auth/login'
import { useCookies } from 'react-cookie';
import swal from 'sweetalert';

export default function Login() {
    let [username, setUsername] = useState('')
    let [password, setPassword] = useState('')
    let [loading, setLoading] = useState(false)
    const [cookies, setCookie] = useCookies(['token']);

    if (cookies.token) {
        window.location.href = '/dashboard'
    }


    function handleSubmit(e) {
        e.preventDefault()
        setLoading(true)
        let data = login(username, password)
        data.then(res => {
            if (res.error) {
                swal("Error!", res.error, "error")
                setLoading(false)
                return;
            }
            swal("Success!", `You are logged with ${res.user.username}`, "success")
            setLoading(false)
            setCookie('token', res.token, { path: '/' });
            setCookie('username', res.user.username, { path: '/' });
            setCookie('id', res.user.id, { path: '/' });
        }) 
    }

  return (
    <div className='container justify-content-center align-items-center'>
        <div className='row justify-content-center align-items-center'>
            <div className='col-12 col-md-6 col-lg-4 glass p-4 mt-5'>
                <h3 className='text-center mb-3'>Login</h3>
                <form onSubmit={handleSubmit}>
                    <div className='mb-3'>
                        <label htmlFor='username' className='form-label'>Username</label>
                        <input value={username} type='text' className='form-control' id='username' onChange={e => setUsername(e.target.value)}/>
                    </div>
                    <div className='mb-3'>
                        <label htmlFor='password' className='form-label'>Password</label>
                        <input value={password} type='password' className='form-control' id='password' onChange={e => setPassword(e.target.value)} />
                    </div>
                    <div className='text-center'>
                        <button className='btn btn-info'>Login</button>
                    </div>
                </form>
                <div className='text-center mt-3'>
                    {loading ?? <div className="spinner-border text-info" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>}
                </div>
                <div className='text-center mt-3'>
                    <Link to='/register'>Don't have an account? Register</Link>
                </div>
            </div>
        </div>
    </div>
  )
}
