import React, {useEffect} from 'react'
import "./styles/main.css"
import Projects from '../components/Projects'
import AboutMe from '../components/AboutMe'
import Heading from '../components/Heading'
import Technologies from '../components/Technologies'

export default function Main() {
  useEffect(() => {
    document.title = 'Joao S. | Home';
  }, []);
  return (
    <div className='mt-5'>
      <Heading />
      <AboutMe />
      <Technologies />
      <Projects />
    </div>
  )
}
