import React from 'react'
import { Link } from 'react-router-dom'
import { FaHome } from 'react-icons/fa'

export default function NotFound() {
  return (
    <div className='my-5 container d-flex justify-content-center align-items-center flex-column'>
                <h3 className='mb-3 fw-bold text-center' data-aos="fade-down" >ERROR 404 😔</h3>
                <p className='text-white text-center contact-tip mb-4' data-aos="fade-down" >This page doesn't exist.</p>
                <Link to="/home"><button className='btn btn-info glass px-5 py-2 mt-5 rounded-pill'><span className='mx-2'>Back Home <FaHome /></span></button></Link>
    </div>
  )
}
