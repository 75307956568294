import axios from "axios"

export const register = async (username, password) => {
    let response;
    try {
        response = await axios.post("/api/auth/register", { username: username, password: password})
    } catch (error) {
        return error.response.data 
    }
    let { data } = response
    return data
}