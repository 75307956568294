import React from 'react'
import { getAllFiles } from '../services/files/getAllFiles'
import { removeFile } from '../services/files/removeFile'
import { BsFillImageFill, BsFillTrashFill, BsDownload } from 'react-icons/bs'
import { AiFillFile, AiOutlineLink, AiFillPlayCircle } from 'react-icons/ai'
import { Link } from 'react-router-dom'

export default function FileList() {
    let [files, setFiles] = React.useState([])

    React.useEffect(() => {
        getAllFiles().then(res => {
            setFiles(res)
        })
    }, [])

    const onDeleteFile = (filename) => {
        removeFile(filename).then(res => {
            setFiles(files.filter(file => file.file_name !== filename))
            console.log(res)
        })
    }

    const streamingFile = (filename, fileType) => {
        if (fileType.includes("image") || fileType.includes("video")){
            return <div className='col-xxl-12 col-lg-12 col-sm-12 col-12 mt-3 px-2 text-center'>
                <Link to={`/streaming/${filename}`}><button className='btn btn-dark h-100 w-100 px-0'>Watch <AiFillPlayCircle /></button></Link>
            </div>
        }
        return;
    }

    if (files)return(
        <div className=''>
            <h5 className='glass p-2 text-center'>List of files!</h5>
            {
                files.map(file => {return (
                    <div className='mt-3 glass p-4 '>
                        <div className='row'>
                            <div className='col-8 text-white fw-bold mb-0 d-flex align-items-center'>
                                <span className='fs-3'>
                                    {file.type.includes("image") || file.type.includes("video") ? <BsFillImageFill /> : <AiFillFile />}
                                </span>
                                <span className='text-white fw-bold mx-3'>{file.file_name}</span>
                            </div>
                            <div className='col-4 text-white fw-bold mb-0 d-flex align-items-center'>{Math.round(file.size / 1024 / 1024)} mb</div>
                        </div>
                        <div className='row mt-1 g-3'>
                            {
                                streamingFile(file.file_name, file.type)
                            }
                            <div className='col-xxl-4 col-lg-6 col-sm-4 col-12 text-center'>
                                <button className='btn btn-light h-100 w-100' onClick={() => navigator.clipboard.writeText("https://joaosouza.me" + file.path)}>Link <AiOutlineLink /></button>
                            </div>
                            <div className='col-xxl-4 col-lg-6 col-sm-4 col-12 text-center'>
                                <a href={"" + file.path}>
                                    <button className='btn btn-info h-100 w-100 px-0'>Download <BsDownload /></button>
                                </a>
                            </div>
                            <div className='col-xxl-4 col-lg-12 col-sm-4 col-12 text-center'>
                                <button className='btn btn-danger h-100 w-100' onClick={(e) => onDeleteFile(file.file_name)}>Delete <BsFillTrashFill /></button>
                            </div>
                        </div>
                    </div>
                )})
            }
        </div>
    )

  return (
    <div className='mt-5'>
        <h5>List of files!</h5>
        {
            files > 0 ? files.map(file => {
                return (
                    <div className='container d-flex flex-column align-items-center'>
                        <p className='text-white fw-bold'>{file.filename}</p>
                        <p className='text-white fw-bold'>{file.size} bytes</p>
                        <p className='text-white fw-bold'>{file.type}</p>
                    </div>
                )
            }) :
            <p className='text-white'>No files!</p>
        }
    </div>
  )
}
